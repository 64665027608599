import React from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {IState} from "../../types/state";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface ISocialLinksProps {
    shape: string
    className: string
}

const SocialLinks: React.FC<ISocialLinksProps> = ({shape, className}) => {

    const classes = classNames(className, "social-links", {
        "social-links--shape--circle": shape === "circle",
        "social-links--shape--rounded": shape === "rounded"
    });
    const social = useSelector((state: IState) => state["general"].social)


    let socialLinks;
    if (social) {
        socialLinks = social.map((item) => (
            <li className="social-links__item" key={item.name}>
                <a
                    className={`social-links__link social-link-color`}
                    href={item.link}
                    aria-label="socials"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LazyLoadImage
                        src={`/storage/${item.icon}`}
                        alt={item.name}
                    />
                </a>
            </li>
        ));
    }

    return (
        <div className={classes}>
            <ul className="social-links__list">{socialLinks}</ul>
        </div>
    );
}


export default React.memo(SocialLinks);
